$sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$serif: 'Times new roman';
$lightGray: #f5f5f5;

#top.compact {
  header {
    padding: 20px 60px 20px 60px;
    border-bottom: 1px solid $lightGray;
    h1 { font-size: 20px; }
    h2 { font-size: 14px; }
  }
}

#top {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px 20px 60px;
    color: #A2A2A2;
    border-bottom: 1px solid #fff;
    transition: all 300ms;

    h1, h2 {
      margin: 0;
      font-weight: normal;
    }

    h1 {
      font-size: 32px;
      font-weight: normal;
      font-family: $serif;
    }

    h2 {
      font-size: 18px;
      font-family: $sans-serif;
    }
  }
}

/************======== S M A L L  H E A D E R ========************/

@media (max-width: 900px) {

  #top {
    header {
      padding: 0;

      h1 { font-size: 26px; }
      h2 { display: none; }
    }

    &.compact {
      header {
        padding: 0 0 20px 0;
        h1 { font-size: 20px; }
      }
    }
  }
}
