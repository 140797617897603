$sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$serif: 'Times new roman';
$gray: #cacaca;
$lightGray: #f5f5f5;


#timersContainer {
  .timer {
    width: 33%;
    min-height: 200px;
    position: relative;
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;

    &:nth-child(3n) {
      flex-grow: 1;
    }

    &.off {
      color: white;
      background-color: black;

      button { color: white; }
    }

    .title, .time { padding: 0 20px; }

    .title { font-size: 34px; }

    .time {
      font-size: 44px;
      font-family: $sans-serif;
    }

    button {
      position: absolute;
      top: 35px;
      right: 35px;
      font-size: 30px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

/************======== S M A L L  H E A D E R ========************/

@media (max-width: 900px) {

  #timersContainer {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    .timer {
      width: auto;
      min-height: 0;
      padding: 0 20px;
      border-right: none;

      .title, .time {
        margin: 20px 0;
        padding: 0;
      }

      .time { font-size: 36px; }

      button {
        top: 20px;
        right: 20px;
      }
    }
  }
}
