$sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$serif: 'Times new roman';
$gray: #cacaca;
$lightGray: #f5f5f5;

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#top {
  border-bottom: 1px solid $gray;
}

#timersContainer {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

/************======== S M A L L  H E A D E R ========************/

@media (max-width: 900px) {
  #top {
    padding: 30px 30px 0 30px;

    &.compact {
      padding: 20px 30px 0 30px;
    }
  }
}
